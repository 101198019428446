$euiSize:     16px !default;

$euiSizeXS:   $euiSize * .25 !default;
$euiSizeS:    $euiSize * .5 !default;
$euiSizeM:    $euiSize * .75 !default;
$euiSizeL:    $euiSize * 1.5 !default;
$euiSizeXL:   $euiSize * 2 !default;
$euiSizeXXL:  $euiSize * 2.5 !default;

$euiColorPrimary: #006BB4 !default;
$euiColorSecondary: #017D73 !default;
$euiColorAccent: #DD0A73 !default;

$euiColorEmptyShade: #FFF !default;
$euiColorLightestShade: #F5F7FA !default;
$euiColorLightShade: #D3DAE6 !default;
$euiColorMediumShade: #98A2B3 !default;
$euiColorDarkShade: #69707D !default;
$euiColorDarkestShade: #343741 !default;
$euiColorFullShade: #000 !default;
$euiColorEmptyShadeDark: #1D1E24 !default;

$euiColorSuccess: $euiColorSecondary !default;
$euiColorWarning: #F5A700 !default;
$euiColorDanger: #BD271E !default;

$euiBorderColor: $euiColorLightShade !default;

$euiAnimSlightResistance: cubic-bezier(.694, .0482, .335, 1) !default;

$euiScrollBar: $euiSize !default;
$euiScrollBarCornerThin: $euiSizeS * .75 !default;

$euiHeaderHeight: $euiSizeXXL + $euiSizeS !default;
$euiHeaderHeightCompensation: $euiHeaderHeight + 1px !default;
