@import "./styles/variables.scss";
@import "./styles/mixins.scss";

// Headers
body {
  @include euiHeaderAffordForFixed;

  &.euiBody--headerIsFixed--double {
    @include euiHeaderAffordForFixed($euiHeaderHeightCompensation * 2);
  }
}

div.edit-bar {
  min-height: 32px;
  background: rgba($euiColorPrimary, 0.32);
  padding-left: 12px;
}

.layout-section {
  border-bottom: 2px solid $euiColorDarkShade;
  background-color: $euiColorLightShade;
  margin-bottom: 5px;
}

.nav-active {
  border-bottom: $euiColorPrimary 1px solid;
}

.primary-fill {
  background: $euiColorPrimary;
  color: $euiColorLightestShade;
}
.primary-fill:focus {
  background: shadeOrTint($euiColorPrimary, 20%, 20%);
  color: $euiColorLightestShade;
}
.primary {
  color: $euiColorPrimary;
}

td.hero-cell {
  font-size: 16pt;
  text-align: center;
}

th.title-cell {
  font-weight: bold;
  text-align: center;
  //background-color: shadeOrTint($euiColorDarkestShade, 66%, 50%);
}

table.widget-data-table {
  font-family: monospace;
  th {
    border: 1px solid $euiColorLightShade;
  }
  td {
    border: 1px solid $euiColorLightShade;
    text-align: center;
  }
  td.error {
    background: darkred;
  }
  td.red {
    color: red;
  }
  td.green {
    color: green;
  }
  td.predict {
    color: red;
  }
}

table.widget-data-table.balance {
  width: 100%;
  table-layout: fixed;

  thead th:first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 4;
    background: $euiColorLightShade;
  }

  thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 26px;
    z-index: 3;
    background: $euiColorLightShade;
    // border: 1px solid red;
    // box-shadow: inset 0 1px 0 $euiColorDarkShade, inset 0 -2px 0 $euiColorDarkShade;
  }

  thead th.corner-slot {
    top: 26px;
  }

  tbody td:first-child {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0px;
    background: $euiColorLightShade;
    // border: 1px solid red;
    // box-shadow: inset 0 1px 0 $euiColorDarkShade, inset 0 -2px 0 $euiColorDarkShade;
  }

  th:after, th:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  th:before {
    top: -1px;
    left: -1px;
    // border-top: 1px solid $euiColorDarkShade;
    // border-left: 1px solid $euiColorDarkShade;
  }

  tbody td:first-child::before, tbody td:first-child::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  tbody td:first-child::before {
    top: -1px;
    left: -1px;
    // border-top: 1px solid $euiColorDarkShade;
    // border-left: 1px solid $euiColorDarkShade;
  }

  td, th {
    white-space: nowrap;
  }
}

.balance.widget-data-container::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.balance.widget-data-container::-webkit-scrollbar-thumb {
  background-color: $euiColorDarkShade;
  border: 6px solid transparent;
  background-clip: content-box;
}

.balance.widget-data-container::-webkit-scrollbar-corner, .balance.widget-data-container::-webkit-scrollbar-track {
  background-color: transparent;
}

table.widget-data-table.balance.expand {
  width: auto;
  table-layout: auto;
  thead th {
    padding: 0px 15px;
  }
  td {
    padding: 0px 15px;
  }
}

.databook-table {
  td.uneditable {
    background: #f0f0f0;
    color: black;
  }

  td.uneditable.current-table-col,
  td.uneditable.current-table-col.highlight {
    background: #ddd;
  }

  td.weekend {
    background: #ffe;
  }

  td.today {
    background: rgb(211, 237, 253);
  }

  .current-table-row,
  .current-table-col {
    background: #f4f8ff;
  }

  .percent-mark {
    color: #888;
  }
}

.dimmed {
  color: #aaa;
  font-style: oblique;
}

.format-editor-expr {
  span.euiExpression__description {
    font-weight: bold;
  }
}

button.book-group-header-button {
  max-width: max-content;
}

button.bookToolBar {
  font-size: 12pt;
}

.databook-card.euiCard {
  button {
    font-size: 12pt;
    line-height: 16pt;
  }
  padding: 10px;
}

.bookToolBar {
  .BookBarDatePicker {
    padding-top: 5px;
    width: 132px;
  }

  .BookBarDatePicker input {
    box-shadow: none;
  }

  .primary-fill {
    background: $euiColorPrimary;
    color: $euiColorLightestShade;
  }
  .primary-fill:focus {
    background: shadeOrTint($euiColorPrimary, 20%, 20%);
    color: $euiColorLightestShade;
  }
  .primary {
    color: $euiColorPrimary;
  }

  .danger-fill {
    background: $euiColorDanger;
    color: $euiColorLightestShade;
  }
  .danger-fill:focus {
    background: shadeOrTint($euiColorDanger, 20%, 20%);
    color: $euiColorLightestShade;
  }
  .danger {
    color: $euiColorDanger;
  }
}

// Editable Tabs
.euiTab {
  &.euiTab__title {
    cursor: default;

    &:hover:not(.euiTab-isSelected) {
      text-decoration: none;
    }

    &:after {
      position: absolute;
      content: '';
      top: $euiSize;
      bottom: 0;
      left: auto;
      right: 0;
      background: $euiBorderColor;
      width: 1px;
    }
  }

  &.euiTab__icons {
    &:after {
      position: absolute;
      content: '';
      top: $euiSize;
      bottom: 0;
      left: 0;
      right: 0;
      background: $euiBorderColor;
      width: 1px;
    }
  }
}

.stealthy {
  position: absolute;
  top: -100000px;
}

// template select in generic vis editor
$euiKeyPadMenuSize: 64px;

.template-select.euiKeyPadMenu {
  width: 100%;
}

.template-select .euiKeyPadMenuItem {
  width: $euiKeyPadMenuSize;
  height: $euiKeyPadMenuSize;
}

.generic-table-container {
  @include euiScrollBar($euiColorDarkShade, $euiColorEmptyShade);
  scroll-padding: 0;
}

table.generic-table {
  font-family: monospace;
  border-collapse: separate;
  border-spacing: 0;

  table-layout: auto;
  white-space: nowrap;

  &.fixed {
    table-layout: fixed;
    white-space: normal;
  }

  &.mobile-fixed {
    table-layout: fixed;
    white-space: normal;

    th:nth-child(1), td:nth-child(1) {
      width: 150px;
    }

    th, td {
      width: 100px;
    }
  }

  th, td {
    border: 1px solid $euiColorLightShade;
    &:not(.fixed) {
      padding: 0px 4px;
    }
    border-top-width: 0px;
    border-left-width: 0px;
  }
  th {
    border-top-width: 1px;
  }

  th {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  th:first-child, td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    border-left-width: 1px;
  }
  th:first-child {
    z-index: 2;
    background-color: #fff;
  }

  tr {
    td:first-child {
      background-color: #fff;
    }

    &:hover {
      td:first-child {
        background-color: inherit;
      }
    }
  }
}

@keyframes ani-notice-me {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.25, 1.25);
  }
  100% {
    transform: scale(1, 1);
  }
}

.notice-me {
  animation: ani-notice-me 2s $euiAnimSlightResistance infinite;
}

.rz-compressed-form.euiFormRow--horizontal {
  .euiFormRow__labelWrapper {
    width: 15%;
  }

  .euiFormRow__fieldWrapper {
    width: 85%;
  }
}

.chart-data-view {
  text-align: center;

  th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
  }
}

.pre-text {
  white-space: pre-wrap;
}

.icp-footer {
  position: absolute;
  bottom: $euiSizeXS;
  font-size: $euiSizeM;
  width: 100%;
  text-align: center;
}
