
@mixin euiScrollBar($thumbColor: $euiColorDarkShade, $trackBackgroundColor: transparent, $size: 'thin') {
  // Firefox's scrollbar coloring cascades, but the sizing does not,
  // so it's being added to this mixin for allowing support wherever custom scrollbars are
  // sass-lint:disable-block no-misspelled-properties
  scrollbar-color: transparentize($thumbColor, .5) $trackBackgroundColor; // Firefox support

  @if ($size == 'thin') {
    scrollbar-width: thin;
  }

  // sass-lint:disable-block no-vendor-prefixes
  &::-webkit-scrollbar {
    width: $euiScrollBar;
    height: $euiScrollBar;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparentize($thumbColor, .5);
    background-clip: content-box;
    border-radius: $euiScrollBar;

    @if ($size == 'thin') {
      border: $euiScrollBarCornerThin solid $trackBackgroundColor;
    } @else {
      border: $euiScrollBarCorner solid $trackBackgroundColor;
    }
  }

  &::-webkit-scrollbar-corner,
  &::-webkit-scrollbar-track {
    background-color: $trackBackgroundColor;
  }
}

@mixin euiHeaderAffordForFixed($headerHeight: $euiHeaderHeightCompensation) {
  // The `&` allows for grouping inside another specific body class.
  // When not applied inside of another selector, it simply renders with the single class
  &.euiBody--headerIsFixed {
    padding-top: $headerHeight;

    // When the EuiHeader is fixed, we need to account for it in the position of the flyout
    &:not(.euiDataGrid__restrictBody) .euiFlyout,
    .euiCollapsibleNav {
      top: $headerHeight;
      height: calc(100% - #{$headerHeight});
    }

    @include euiBreakpoint('m', 'l', 'xl') {
      .euiPageSideBar--sticky {
        max-height: calc(100vh - #{$headerHeight});
        top: #{$headerHeight};
      }
    }

    &:not(.euiDataGrid__restrictBody) .euiOverlayMask--belowHeader {
      top: #{$headerHeight};
    }
  }
}

$euiBreakpoints: (
  'xs': 0,
  's':  575px,
  'm':  768px,
  'l':  992px,
  'xl': 1200px
) !default;

$euiBreakpointKeys: map-keys($euiBreakpoints);

@mixin euiBreakpoint($sizes...) {
  // Loop through each size parameter
  @each $size in $sizes {
    // Store the location of the size in the list to check against
    $index: index($euiBreakpointKeys, $size);

    // Check to make sure it exists in the allowed breakpoint names
    @if ( $index ) {

      // Set the min size to the value of the size
      $minSize: map-get($euiBreakpoints, $size);

      // If it is the last item, don't give it a max-width
      @if ( $index == length($euiBreakpointKeys) ) {
        @media only screen and (min-width: $minSize) {
          @content;
        }
      // If it's not the last item, add a max-width
      } @else {

        // Set the max size to the value of the next size (-1px as to not overlap)
        $maxSize: map-get($euiBreakpoints, nth($euiBreakpointKeys, $index + 1)) - 1px;

        // If it's the the first item, don't set a min-width
        @if ( $index == 1 ) {
          @media only screen and (max-width: $maxSize) {
            @content;
          }
        // Otherwise it should have a min and max width
        } @else {
          @media only screen and (min-width: $minSize) and (max-width: $maxSize) {
            @content;
          }
        }
      }
    // If it's not a known breakpoint, throw a warning
    } @else {
      @warn "euiBreakpoint(): '#{$size}' is not a valid size in $euiBreakpoints. Accepted values are '#{$euiBreakpointKeys}'";
    }
  }
}
